import React from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'
import markerIcon from '../../images/place-marker.png'
import { Text } from '../../GlobalStyles'

const API_KEY = 'AIzaSyChLDTFD-838Go6tCVCYQw_nx08XaKkodA'
const locationCenter = {
  lat: 37.416698,
  lng: 25.339387
}
const initialZoom = 12

const Marker = () => (
  <MarkerWrapper>
    <MarkerIcon />
    <LayoutText>Nikos Gallop Restaurant</LayoutText>
  </MarkerWrapper>
)

const Map = ({ className }) => {
  return (
    <div className={className} style={{ height: '250px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={locationCenter}
        defaultZoom={initialZoom}>
        <Marker lat={37.416698} lng={25.339387} />
      </GoogleMapReact>
    </div>
  )
}

export default Map

const MarkerWrapper = styled.div`
  position: relative;
  width: 30px;
  transform: translate(-50%, -50%);
`

const MarkerIcon = styled.img.attrs({
  src: markerIcon
})`
  width: 100%;
`

const LayoutText = styled(Text)`
  display: block;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  color: #dc893e;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
`
