import styled from 'styled-components'
import Img from '../../components/Img'
import stripedBg from '../../images/striped-bg.png'
import { secondaryFont, primaryFont } from '../../GlobalStyles'
import Map from '../../components/Map'

export const Wrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;
`

export const ImageMap = styled(Img).attrs({
  filename: 'locationMap.png'
})`
  margin-bottom: 30px;
  margin-top: 30px;
`

export const FindUsInfo = styled.div`
  ${secondaryFont};
  border-left: 1px solid black;
  border-right: 1px solid black;
  padding: 10px 0;
  font-size: 22px;
  & > div {
    background: url(${stripedBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 20px 15px;
  }
`

export const GoogleMap = styled(Map)`
  margin-top: 60px;
  margin-bottom: 25px;
`

export const GetHereWrapper = styled.div`
  margin-top: 30px;
  ${secondaryFont};
  & > h2 {
    font-size: 30px;
    ${primaryFont};
    margin: 0;
  }
  label {
    font-weight: bold;
    display: block;
  }
`
