import React from 'react'
import SEO from '../../components/seo'
import { FindUsInfo, GetHereWrapper, GoogleMap, ImageMap, Wrapper } from './css'
import { useTranslation } from '../../i18n'

const Location = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <SEO
        title="Location"
        keywords={[`nikos gallop`, `Location`, `how to find us`]}
      />
      <ImageMap />
      <FindUsInfo>
        <div>{t('location_find_us_info')}</div>
      </FindUsInfo>
      <GoogleMap />
      <GetHereWrapper>
        <h2>{t('location_how_to_get_here')}</h2>
        <label>{t('location_MMM_type')}</label>
        <span>{t('location_MMM_desc')}</span>
        <label>{t('location_vehicle_type')}</label>
        <span>{t('location_vehicle_desc')}</span>
      </GetHereWrapper>
    </Wrapper>
  )
}

export default Location
