import React from 'react'
import Layout from '../layout'
import Location from '../screens/Location'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Location />
  </Layout>
)

export default IndexPage
